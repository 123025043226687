import axios from "axios";
import extend from "@/util/extend";
import {isHttpErrorCode} from "@/http-client/httpErrorHandler";

function HttpClient(baseURL, $store) {
    this.config = {
        baseURL,
        headers: {
            'X-Requested-With':'XMLHttpRequest',
            'Content-Type':'application/json'
        }
    }
    this.httpRequestList = {}
    this.$store = $store
}

HttpClient.prototype.create = function (config, requestId) {
    const $axios = axios.create(extend(this.config, config || {}))

    $axios.interceptors.request.use(request => {
        // set auth if exists
        const token = this.$store.getters["user/getToken"]
        if (token) {
            request.headers["Authorization"] = 'Bearer ' + token
        }

        if (requestId) {
            this.cancelRequest(requestId)
            this.addRequestId(requestId, ()=>{
                const httpClientSource = axios.CancelToken.source()
                request.cancelToken = httpClientSource.token
                return httpClientSource;
            })
        }

        return request;
    });

    $axios.interceptors.response.use(response => {
        return response
    }, error => {
        if (isHttpErrorCode(['auth.expired_token','auth.unauthorized', 'auth.invalid_token'], error)) {
            this.$store.dispatch('user/removeUser')
        }

        return Promise.reject({...error});
    });

    return $axios;
}

HttpClient.prototype.addRequestId = function (requestId, source) {
    if (typeof source !== "function") {
        return;
    }

    if (!Object.prototype.hasOwnProperty.call(this.httpRequestList, requestId)) {
        this.httpRequestList[requestId] = [];
    }

    this.httpRequestList[requestId].push(source.call())
};

HttpClient.prototype.cancelRequest = function (requestId) {
    if (Object.prototype.hasOwnProperty.call(this.httpRequestList, requestId)) {
        const requests = this.httpRequestList[requestId];
        for (let x=0; x<requests.length; x++) {
            requests[x].cancel('canceled')
        }
    }
}

HttpClient.prototype.isRequestCanceled = function (error) {
    return error.message && 'canceled' === error.message
}

export default {
    install(app, pluginOptions) {
        const $store = app.config.globalProperties.$store
        if (!$store) {
            throw new Error("Http client plugin require vuex store. Please install it after they are already installed.")
        }

        app.config.globalProperties.$http = new HttpClient(pluginOptions.baseUrl, $store)
    }
}