import { createStore } from 'vuex'
import user from "@/store/user";
import dashboard from "@/store/dashboard";
import transfer from "@/store/transfer";
import userBalance from "@/store/userBalance";

const STORAGE_UUID_KEY = '_uuid'

export default createStore({
  state: {
    uuid: localStorage.getItem(STORAGE_UUID_KEY)
  },
  getters: {
    getUuid: state => state.uuid
  },
  actions: {
    setUuid({commit}, value) {
      commit('SET_UUID', value)
    }
  },
  mutations: {
    SET_UUID(state, value) {
      state.uuid = value
      localStorage.setItem(STORAGE_UUID_KEY, value || '')
    }
  },
  modules: {
    user,
    dashboard,
    transfer,
    userBalance
  }
})
