import {createApp} from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import routerGuard from "@/router/routerGuard";
import uuid from "@/uuid";
import appMeta from "@/router/appMeta";
import fontAwesome from "@/font-awesome";
import httpClient from "@/http-client";
import smsPayAssets from "@/sms-pay-assets";
import "@/assets/app.scss"
import "bootstrap/dist/js/bootstrap.min.js"
import VueTelInput from 'vue-tel-input'
import moment from "moment";
import httpGoproxy from "@/http-goproxy";

moment.locale("id");

createApp(App)
    .use(router)
    .use(store)
    .use(routerGuard, {loginName: 'Login'})
    .use(uuid)
    .use(appMeta)
    .use(fontAwesome)
    .use(httpClient, {baseUrl: 'https://api.smspay.co.id'})
    .use(smsPayAssets, { baseUrl: 'https://assets.smspay.co.id' })
    .use(httpGoproxy, { baseUrl: 'https://vps.smspay.co.id:8006' })
    .use(VueTelInput)
    .mount('#app')