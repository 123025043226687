<template>
    <slot name="navbar"/>
    <slot/>
    <slot name="footer"/>
</template>

<script>

export default {
    name: "GuestLayout"
}
</script>

<style scoped>

</style>