<template>
    <nav class="navbar sticky-top navbar-expand-lg navbar-light bg-white">
        <div class="container">
            <router-link to="/" class="navbar-brand">
                <img src="/assets/images/favicon-32x32.png" alt="" width="32" height="32"> SMS Pay
            </router-link>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#pageNavbar" aria-controls="pageNavbar" aria-expanded="false" aria-label="page navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="pageNavbar">
                <ul v-if="hasNavItemsStart" class="navbar-nav mb-2 mb-lg-0">
                    <slot name="navItemsStart"/>
                </ul>
                <ul v-if="hasNavItemsEnd" class="navbar-nav ms-auto mb-2 mb-lg-0">
                    <slot name="navItemsEnd"/>
                </ul>
                <div :class="{'d-flex justify-content-between my-3 my-md-0': true, 'ms-auto': !hasNavItemsEnd}">
                    <router-link to="/register" class="btn btn-primary ms-lg-2">Daftar</router-link>
                    <router-link to="/login" class="btn btn-outline-primary ms-lg-3">Masuk</router-link>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
export default {
    name: "GuestNavbar",
    computed: {
        hasNavItemsStart() {
            return typeof this.$slots.navItemsStart !== "undefined"
        },
        hasNavItemsEnd() {
            return typeof this.$slots.navItemsEnd !== "undefined"
        }
    }
}
</script>

<style scoped>

</style>