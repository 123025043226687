function extend() {
    for(let x=0; x<arguments.length; x++) {
        for(let key in arguments[x]) {
            if (Object.prototype.hasOwnProperty.call(arguments[x], key)) {
                arguments[0][key] = arguments[x][key]
            }
        }
    }

    return arguments[0]
}

export default extend