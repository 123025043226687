<template>
    <footer class="text-light">
        <div class="container py-5">
            <div class="row">
                <div class="col-md-3 mb-3 mb-lg-0">
                    <h5>Alamat</h5>
                    Jl Ciremai Raya Ruko No 22 Kel Larangan Kec Harjamukti Kota Cirebon 45141.
                </div>
                <div class="col-md-3 mb-3 mb-lg-0">
                    <h5>Kontak Kami</h5>
                    <ul class="list-unstyled">
                        <li><a class="link-light" href="mailto:cs@smspay.co.id" target="_blank" rel="noopener noreferrer">Email CS : cs@smspay.co.id</a></li>
                        <li><a class="link-light" href="https://api.whatsapp.com/send?phone=62881023169999" target="_blank" rel="noopener noreferrer">Telp. / Whatsapp : 0881 02316 9999</a></li>
                        <li><a class="link-light" href="https://www.instagram.com/sms.pay" target="_blank" rel="noopener noreferrer">Instagram : @smspay_id</a></li>
                        <li><a class="link-light" href="https://www.facebook.com/SMS-Pay-111124577421536">Facebook : Sms Pay</a></li>
                    </ul>
                </div>
                <div class="col-md-auto ms-auto">
                    <h5>Ikuti Kami di</h5>
                    <div class="social-link">
                        <a href="https://www.instagram.com/smspay_id" target="_blank" rel="noopener noreferrer"><fa-icon :icon="['fab', 'instagram']"/></a>
                        <a href="https://www.facebook.com/SMS-Pay-111124577421536"><fa-icon :icon="['fab', 'facebook']"/></a>
                        <a href="#"><fa-icon :icon="['fab', 'youtube']"/></a>
                        <a href="https://twitter.com/smspay_id"><fa-icon :icon="['fab', 'twitter']"/></a>
                        <a href="https://api.whatsapp.com/send?phone=62881023169999" target="_blank" rel="noopener noreferrer"><fa-icon :icon="['fab', 'whatsapp']"/></a>
                    </div>
                </div>
            </div>
        </div>
        <div class="container py-3 d-flex flex-column flex-column-reverse flex-lg-row justify-content-lg-between">
            <div>Copyright © {{ currentYear }} SMS Pay Indonesia!</div>
            <div><a href="https://smspay.co.id/2020/10/31/syarat-penggunaan-dan-kebijakan-privasi/" class="link-light" target="_blank">Syarat Penggunaan dan Kebijakan Privasi</a></div>
        </div>
    </footer>
</template>

<script>
export default {
    name: "GuestFooter",
    computed: {
        currentYear() {
            return new Date().getFullYear()
        },
    }
}
</script>

<style scoped>
footer {
    background-color: #0F2634;
}
a {
    text-decoration: none;
}
.social-link a {
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 33px;
    padding: 0;
    border: 2px solid #fff;
    border-radius: 50%;
    text-align: center;
    color: #fff;
    margin: 0 10px 5px 0;
    transition: .3s ease;
    font-size: 20px;
}
.social-link a:hover{
    background:#fff;
    color:#0F2634;
}
</style>