import {v4 as UuidV4} from 'uuid'

export default {
    install(app) {
        const $store = app.config.globalProperties.$store
        if (!$store) {
            throw new Error("UUID plugin require vuex store. Please install it after they are already installed.")
        }

        app.config.globalProperties.$uuid = {
            obtain() {
                let uuid = $store.getters.getUuid
                if (!uuid) {
                    uuid = UuidV4()
                    $store.dispatch("setUuid", uuid)
                }

                return uuid
            }
        }
    }
}