import axios from "axios";
import extend from "@/util/extend";

function HttpGoProxy(baseURL) {
    this.baseURL = baseURL
    this.config = {
        baseURL,
        headers: {'Content-Type':'application/json'}
    }
}

HttpGoProxy.prototype.create = function (headers) {
    return axios.create({
        baseURL: this.baseURL,
        headers: extend({'Content-Type':'application/json'}, headers || {})
    })
}

export default {
    install(app, pluginOptions) {
        app.config.globalProperties.$httpGoProxy = new HttpGoProxy(pluginOptions.baseUrl)
    }
}